

//九巴
export async function fetchArrivedTime(stop: string, busNumber: string) {
    const url = `https://data.etabus.gov.hk/v1/transport/kmb/eta/${stop}/${busNumber}/1`

    try {
        const response = await fetch(url)
        const data = await response.json()
        return data
    } catch (e) {
        console.log(e)
        return null
    }
}

export async function fetchStop() {
    const url = `https://data.etabus.gov.hk/v1/transport/kmb/route-stop`

    try {
        const response = await fetch(url)
        const data = await response.json()
        return data
    } catch (e) {
        console.log(e)
        return null
    }
}

//城巴
export async function fetchCtbStop(
    busNumber: string,
    dir: string
) {
    const url = `https://rt.data.gov.hk/v2/transport/citybus/route-stop/ctb/${busNumber}/${dir}`
    try {
        const response = await fetch(url)
        const data = response.json()
        return data
    } catch (e) {
        console.log(e)
        return null
    }

}

export async function fetchCtbArrivedTime(
    busNumber: string,
    stop: string
) {
    try {
        const url = `https://rt.data.gov.hk/v2/transport/citybus/eta/ctb/${stop}/${busNumber}`
        const response = await fetch(url)
        const data = await response.json()
        return data
    } catch (e) {
        console.log(e)
        return null
    }
}
import { fetchArrivedTime, fetchCtbArrivedTime, fetchCtbStop, fetchStop } from "./fetch";


export async function stopName(
    busNumber: string,
    dir: string,
    seq: string
): Promise<string | null> {
    const data = await fetchStop()
    const findStop = data.data.find(
        (item: any) => item.seq === seq
        && item.route === busNumber
        && item.bound === dir
    ) as { stop: string } | undefined
    return findStop? findStop.stop: null
}


export async function busTime(
    busNumber: string,
    dir: string,
    seq: string
) {
    const stop = await stopName(busNumber, dir, seq)
    const data = await fetchArrivedTime(stop as string, busNumber)
    const nowTime= new Date().getTime()
    if(data.data[0].eta){
        const dataTime = data.data.map((item: any) => {
        const etaTime = new Date(item.eta).getTime()
        const time = Math.floor((etaTime - nowTime)/ (1000 * 60) + 1)
       
        return time
        })
        return dataTime
        // if(dataTime[0] < 10) {
        //     dataTime[0] = '-'
        //     return dataTime
        // } else {
        //     return dataTime
        // }
        
    } else {
        return null
    }

}

//城巴
export async function btcBusTime(
    busNumber: string,
    dirGo: string,
    dir: string,
    seq: number
) {
    const data = await fetchCtbStop(busNumber, dir)
    const findStop = data.data.find(
        (item: any) => item.seq === seq
        && item.route === busNumber
        && item.dir === dirGo
    )
    const stop = findStop.stop
    // console.log(busNumber, stop)

    const timeData = await fetchCtbArrivedTime(busNumber, stop)
    const nowTime= new Date().getTime()
    if(timeData.data[0].eta){
        const dataTime = timeData.data.map((item: any) => {
        const etaTime = new Date(item.eta).getTime()
        const time = Math.floor((etaTime - nowTime)/ (1000 * 60) + 1)
        
        return time
        })
        return dataTime
    } else {
        return null
    }
}

import { useEffect, useState } from "react";
import { btcBusTime, busTime } from "./api/route";
import './BusPage.css'


interface TimeState {
    item1: number | null;
    item2: number | null;
    item3: number | null;
}

export function STtoTC() {
    const [ e42Time, setE42Time ] = useState<TimeState | null>(null)
    const [ a41Time, setA41Time ] = useState<TimeState | null>(null)

    const [ e32aTime, setE32aTime ] = useState<TimeState | null>(null)
    const [ e21aTime, setE21aTime ] = useState<TimeState | null>(null)
    const [ e21dTime, setE21dTime ] = useState<TimeState | null>(null)
    const [ e11aTime, setE11aTime ] = useState<TimeState | null>(null)
    const [ e11bTime, setE11bTime ] = useState<TimeState | null>(null)
    const [ timeout, setTimeout ] = useState<Date>(new Date())

    //E42沙田出(沙田站)
    const e42Bus = "E42"
    const e42Seq = '3'

    //A41沙田出(沙田站)
    const a41Bus = "A41"
    const a41Seq = '10'

    //E32A去東涌(轉車站)
    const e32aBus = 'E32A';
    const e32aSeq = '14';

    //E21A去東涌(轉車站)
    const e21aBus = 'E21A'
    const e21aSeq = 23

    //E21D去東涌(轉車站)
    const e21dBus = 'E21D'
    const e21dSeq = 21

    //E11A去東涌(轉車站)
    const e11aBus = 'E11A'
    const e11aSeq = 16

    //E11B去東涌(轉車站)
    const e11bBus = 'E11B'
    const e11bSeq = 16

    const dirGo = 'O'
    const dir = 'outbound'

    useEffect(() => {
        const fetchData = async () => {
            try {
                const fetchE32a = await busTime(e32aBus, dirGo, e32aSeq)
                const allTime = {
                    item1: fetchE32a[0] || null,
                    item2: fetchE32a[1] || null,
                    item3: fetchE32a[2] || null
                }
                setE32aTime(allTime);
            } catch (e) {
                console.log('E32A no data')
            }

            try {
                const fetchE21a = await btcBusTime(e21aBus, dirGo, dir, e21aSeq)
                const allTime = {
                    item1: fetchE21a[0] || null,
                    item2: fetchE21a[1] || null,
                    item3: fetchE21a[2] || null
                }
                setE21aTime(allTime);
            } catch (e) {
                console.log('E21A no data')
            }

            try {
                const fetchE21d = await btcBusTime(e21dBus, dirGo, dir, e21dSeq)
                const allTime = {
                    item1: fetchE21d[0] || null,
                    item2: fetchE21d[1] || null,
                    item3: fetchE21d[2] || null
                }
                setE21dTime(allTime);
            } catch (e) {
                console.log('E21D no data')
            }

            try {
                const fetchE11a = await btcBusTime(e11aBus, dirGo, dir, e11aSeq)
                const allTime = {
                    item1: fetchE11a[0] || null,
                    item2: fetchE11a[1] || null,
                    item3: fetchE11a[2] || null
                }
                setE11aTime(allTime);
            } catch (e) {
                console.log('E11A no data')
            }

            try {
                const fetchE11b = await btcBusTime(e11bBus, dirGo, dir, e11bSeq)
                const allTime = {
                    item1: fetchE11b[0] || null,
                    item2: fetchE11b[1] || null,
                    item3: fetchE11b[2] || null
                }
                setE11bTime(allTime);
            } catch (e) {
                console.log('E11B no data')
            }

            try {
                const fetchE42 = await busTime(e42Bus, dirGo, e42Seq)
                const allTime = {
                    item1: fetchE42[0] || null,
                    item2: fetchE42[1] || null,
                    item3: fetchE42[2] || null
                }
                setE42Time(allTime);
            } catch (e) {
                console.log('E42 no data')
            }

            try {
                const fetchA41 = await busTime(a41Bus, dirGo, a41Seq)
                const allTime = {
                    item1: fetchA41[0] || null,
                    item2: fetchA41[1] || null,
                    item3: fetchA41[2] || null
                }
                setA41Time(allTime);
            } catch (e) {
                console.log('A41 no data')
            }
        }
        fetchData()
        
        const interval = setInterval(() => {
            setTimeout(new Date());
        }, 5000);
        return () => clearInterval(interval);

    }, [timeout])
  
    const subBusExpectTime = 'เวลาที่คาดว่าจะถึง'
    
    return (
        <div className="mainPage">
            <h2>Shatin ไป Tung Chung</h2>
            {/* 沙田站 */}
            <div className="location"> 
                <h3>Sha Tin Station<br></br>(สถานที่ทำงาน)</h3>
                <div>
                    <div className="carName">{e42Bus}</div>
                    { e42Time !== null ? (
                        <div>
                            {e42Time.item1 !== null && <div> {e42Time.item1} นาที</div>}
                            {e42Time.item2 !== null && <div> {e42Time.item2} นาที</div>}
                            {e42Time.item3 !== null && <div> {e42Time.item3} นาที</div>}
                        </div>
                    ) : (
                        <div>{e42Bus}<br></br>ไม่มีรถเมล์</div>
                    )}
                </div>

                <div>
                    <div className="carName">{a41Bus}</div>
                    { a41Time !== null ? (
                        <div>
                            {a41Time.item1 !== null && <div> {a41Time.item1} นาที</div>}
                            {a41Time.item2 !== null && <div> {a41Time.item2} นาที</div>}
                            {a41Time.item3 !== null && <div> {a41Time.item3} นาที</div>}
                        </div>
                    ) : (
                        <div>{a41Bus}<br></br>ไม่มีรถเมล์</div>
                    )}
                </div>
            </div>

            {/* 轉車站(預計時間) */}
            <div className="expectLocation">
                <h3>Lantau Link Interchange Station<br></br>(สถานีเปลี่ยนรถ)<br></br>(คาดการณ์)</h3>
                <div>
                    <div className="carName">{e42Bus}<br></br>{subBusExpectTime}</div>
                    { e42Time !== null ? (
                        <div>
                            {e42Time.item1 !== null && <div> {e42Time.item1 + 39} นาที</div>}
                            {e42Time.item2 !== null && <div> {e42Time.item2 + 39} นาที</div>}
                            {e42Time.item3 !== null && <div> {e42Time.item3 + 39} นาที</div>}
                        </div>
                    ) : (
                        <div>{e42Bus}<br></br>ไม่มีรถเมล์</div>
                    )}
                </div>

                <div>
                    <div className="carName">{a41Bus}<br></br>{subBusExpectTime}</div>
                    { a41Time !== null ? (
                        <div>
                            {a41Time.item1 !== null && <div> {a41Time.item1 + 32} นาที</div>}
                            {a41Time.item2 !== null && <div> {a41Time.item2 + 32} นาที</div>}
                            {a41Time.item3 !== null && <div> {a41Time.item3 + 32} นาที</div>}
                        </div>
                    ) : (
                        <div>{a41Bus}<br></br>ไม่มีรถเมล์</div>
                    )}
                </div>
            </div>

            {/* 轉車站 */}
            <div className="location"> 
            <h3>Lantau Link Interchange Station<br></br>(สถานีเปลี่ยนรถ)</h3>
                <div>
                    <div className="carName">{e32aBus} </div>
                    { e32aTime !== null ? (
                        <div>
                            {e32aTime.item1 !== null && <div> {e32aTime.item1} นาที</div>}
                            {e32aTime.item2 !== null && <div> {e32aTime.item2} นาที</div>}
                            {e32aTime.item3 !== null && <div> {e32aTime.item3} นาที</div>}
                        </div>
                    ) : (
                        <div>{e32aBus}<br></br>ไม่มีรถเมล์</div>
                    )}
                </div>

                <div>
                    <div className="carName">{e21aBus} </div>
                    { e21aTime !== null ? (
                        <div>
                            {e21aTime.item1 !== null && <div> {e21aTime.item1} นาที</div>}
                            {e21aTime.item2 !== null && <div> {e21aTime.item2} นาที</div>}
                            {e21aTime.item3 !== null && <div> {e21aTime.item3} นาที</div>}
                        </div>
                    ) : (
                        <div>{e21aBus}<br></br>ไม่มีรถเมล์</div>
                    )}
                </div>

                <div>
                    <div className="carName">{e21dBus} </div>
                    { e21dTime !== null ? (
                        <div>
                            {e21dTime.item1 !== null && <div> {e21dTime.item1} นาที</div>}
                            {e21dTime.item2 !== null && <div> {e21dTime.item2} นาที</div>}
                            {e21dTime.item3 !== null && <div> {e21dTime.item3} นาที</div>}
                        </div>
                    ) : (
                        <div>{e21dBus}<br></br>ไม่มีรถเมล์</div>
                    )}
                </div>

                <div>
                    <div className="carName">{e11aBus}</div>
                    { e11aTime !== null ? (
                        <div>
                            {e11aTime.item1 !== null && <div> {e11aTime.item1} นาที</div>}
                            {e11aTime.item2 !== null && <div> {e11aTime.item2} นาที</div>}
                            {e11aTime.item3 !== null && <div> {e11aTime.item3} นาที</div>}
                        </div>
                    ) : (
                        <div>{e11aBus}<br></br>ไม่มีรถเมล์</div>
                    )}
                </div>

                <div>
                    <div className="carName">{e11bBus}</div>
                    { e11bTime !== null ? (
                        <div>
                            {e11bTime.item1 !== null && <div> {e11bTime.item1} นาที</div>}
                            {e11bTime.item2 !== null && <div> {e11bTime.item2} นาที</div>}
                            {e11bTime.item3 !== null && <div> {e11bTime.item3} นาที</div>}
                        </div>
                    ) : (
                        <div>{e11bBus}<br></br>ไม่มีรถเมล์</div>
                    )}
                </div>
            </div>
            
            {/* 裕雅苑(預計時間) */}
            <div className="expectLocation">
                <h3>Tung Chung Yu Nga Court<br></br>(ด้านล่างของบ้าน)</h3>
                <div>
                    <div className="carName">{e32aBus}<br></br>{subBusExpectTime}</div>
                    { e32aTime !== null ? (
                        <div>
                            {e32aTime.item1 !== null && <div> {e32aTime.item1 + 14} นาที</div>}
                            {e32aTime.item2 !== null && <div> {e32aTime.item2 + 14} นาที</div>}
                            {e32aTime.item3 !== null && <div> {e32aTime.item3 + 14} นาที</div>}
                        </div>
                    ) : (
                        <div>{e32aBus}<br></br>ไม่มีรถเมล์</div>
                    )}
                </div>

                <div>
                    <div className="carName">{e21aBus}<br></br>{subBusExpectTime}</div>
                    { e21aTime !== null ? (
                        <div>
                            {e21aTime.item1 !== null && <div> {e21aTime.item1 + 14} นาที</div>}
                            {e21aTime.item2 !== null && <div> {e21aTime.item2 + 14} นาที</div>}
                            {e21aTime.item3 !== null && <div> {e21aTime.item3 + 14} นาที</div>}
                        </div>
                    ) : (
                        <div>{e21aBus}<br></br>ไม่มีรถเมล์</div>
                    )}
                </div>

                <div>
                    <div className="carName">{e21dBus}<br></br>{subBusExpectTime}</div>
                    { e21dTime !== null ? (
                        <div>
                            {e21dTime.item1 !== null && <div> {e21dTime.item1 + 14} นาที</div>}
                            {e21dTime.item2 !== null && <div> {e21dTime.item2 + 14} นาที</div>}
                            {e21dTime.item3 !== null && <div> {e21dTime.item3 + 14} นาที</div>}
                        </div>
                    ) : (
                        <div>{e21dBus}<br></br>ไม่มีรถเมล์</div>
                    )}
                </div>

                <div>
                    <div className="carName">{e11aBus}<br></br>{subBusExpectTime}</div>
                    { e11aTime !== null ? (
                        <div>
                            {e11aTime.item1 !== null && <div> {e11aTime.item1 + 14} นาที</div>}
                            {e11aTime.item2 !== null && <div> {e11aTime.item2 + 14} นาที</div>}
                            {e11aTime.item3 !== null && <div> {e11aTime.item3 + 14} นาที</div>}
                        </div>
                    ) : (
                        <div>{e11aBus}<br></br>ไม่มีรถเมล์</div>
                    )}
                </div>

                <div>
                    <div className="carName">{e11bBus}<br></br>{subBusExpectTime}</div>
                    { e11bTime !== null ? (
                        <div>
                            {e11bTime.item1 !== null && <div> {e11bTime.item1 + 14} นาที</div>}
                            {e11bTime.item2 !== null && <div> {e11bTime.item2 + 14} นาที</div>}
                            {e11bTime.item3 !== null && <div> {e11bTime.item3 + 14} นาที</div>}
                        </div>
                    ) : (
                        <div>{e11bBus}<br></br>ไม่มีรถเมล์</div>
                    )}
                </div>
            </div>

        </div>
    );
}
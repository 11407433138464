import { Link } from "react-router-dom"
import './MainPage.css'

export function MainPage() {
    const goWork = 'เข้างาน'
    const getOffWork = 'เลิกงาน'
    const AllBus = '裕雅苑(全部巴士)'
    
    return (
        <div className='main no-select'>
            <Link to='/TCtoST'>{goWork}</Link>
            <Link to='/STtoTC'>{getOffWork}</Link>
            <Link to='/AllBusForYuNga'>{AllBus}</Link>
        </div>
    )
}
import { useEffect, useState } from "react";
import { btcBusTime, busTime } from "./api/route";
import './BusPage.css'


interface TimeState {
    item1: number | null;
    item2: number | null;
    item3: number | null;
}

export function AllBusForYuNga() {
    const [ e32aTime, setE32aTime ] = useState<TimeState | null>(null)
    const [ e36aTime, setE36aTime ] = useState<TimeState | null>(null)
    const [ e21aTime, setE21aTime ] = useState<TimeState | null>(null)
    const [ e21dTime, setE21dTime ] = useState<TimeState | null>(null)
    const [ e11aTime, setE11aTime ] = useState<TimeState | null>(null)
    const [ e11bTime, setE11bTime ] = useState<TimeState | null>(null)

    const [ e32aBackTime, setE32aBackTime ] = useState<TimeState | null>(null)
    const [ e21aBackTime, setE21aBackTime ] = useState<TimeState | null>(null)
    const [ e21dBackTime, setE21dBackTime ] = useState<TimeState | null>(null)
    const [ e11aBackTime, setE11aBackTime ] = useState<TimeState | null>(null)
    const [ e11bBackTime, setE11bBackTime ] = useState<TimeState | null>(null)

    const [ timeout, setTimeout ] = useState<Date>(new Date())

    //E32A東涌出(裕雅苑)
    const e32aBus = 'E32A';
    const e32aSeq = '10';

    //E36A東涌出(裕雅苑)
    const e36aBus = 'E36A';
    const e36aSeq = '14';

    //E21A東涌出(裕雅苑)
    const e21aBus = 'E21A'
    const e21aSeq = 18

    //E21D東涌出(裕雅苑)
    const e21dBus = 'E21D'
    const e21dSeq = 18

    //E11A東涌出(裕雅苑)
    const e11aBus = 'E11A'
    const e11aSeq = 20

    //E11B東涌出(裕雅苑)
    const e11bBus = 'E11B'
    const e11bSeq = 15

    const dirGo = 'I';
    const dir = 'inbound'


    //E32A去東涌(轉車站)
    const e32aBackBus = 'E32A';
    const e32aBackSeq = '14';

    //E21A去東涌(轉車站)
    const e21aBackBus = 'E21A'
    const e21aBackSeq = 23

    //E21D去東涌(轉車站)
    const e21dBackBus = 'E21D'
    const e21dBackSeq = 21

    //E11A去東涌(轉車站)
    const e11aBackBus = 'E11A'
    const e11aBackSeq = 16

    //E11B去東涌(轉車站)
    const e11bBackBus = 'E11B'
    const e11bBackSeq = 16

    const dirBack = 'O'
    const dirB = 'outbound'

    useEffect(() => {
        const fetchData = async () => {
            //出去
            try {
                const fetchE32a = await busTime(e32aBus, dirGo, e32aSeq)

                const allTime = {
                    item1: fetchE32a[0] || null,
                    item2: fetchE32a[1] || null,
                    item3: fetchE32a[2] || null
                }
                setE32aTime(allTime);
            } catch (e) {
                console.log('E32A no data')
            }

            try {
                const fetchE36a = await busTime(e36aBus, dirGo, e36aSeq)

                const allTime = {
                    item1: fetchE36a[0] || null,
                    item2: fetchE36a[1] || null,
                    item3: fetchE36a[2] || null
                }
                setE36aTime(allTime);
            } catch (e) {
                console.log('E36A no data')
            }

            try {
                const fetchE21a = await btcBusTime(e21aBus, dirGo, dir, e21aSeq)
                const allTime = {
                    item1: fetchE21a[0] || null,
                    item2: fetchE21a[1] || null,
                    item3: fetchE21a[2] || null
                }
                setE21aTime(allTime);
            } catch (e) {
                console.log('E21A no data')
            }

            try {
                const fetchE21d = await btcBusTime(e21dBus, dirGo, dir, e21dSeq)
                const allTime = {
                    item1: fetchE21d[0] || null,
                    item2: fetchE21d[1] || null,
                    item3: fetchE21d[2] || null
                }
                setE21dTime(allTime);
            } catch (e) {
                console.log('E21D no data')
            }

            try {
                const fetchE11a = await btcBusTime(e11aBus, dirGo, dir, e11aSeq)
                const allTime = {
                    item1: fetchE11a[0] || null,
                    item2: fetchE11a[1] || null,
                    item3: fetchE11a[2] || null
                }
                setE11aTime(allTime);
            } catch (e) {
                console.log('E11A no data')
            }

            try {
                const fetchE11b = await btcBusTime(e11bBus, dirGo, dir, e11bSeq)
                const allTime = {
                    item1: fetchE11b[0] || null,
                    item2: fetchE11b[1] || null,
                    item3: fetchE11b[2] || null
                }
                setE11bTime(allTime);
            } catch (e) {
                console.log('E11B no data')
            }

            //回來
            try {
                const fetchE32aBack = await busTime(e32aBackBus, dirBack, e32aBackSeq)
                const allTime = {
                    item1: fetchE32aBack[0] || null,
                    item2: fetchE32aBack[1] || null,
                    item3: fetchE32aBack[2] || null
                }
                setE32aBackTime(allTime);
            } catch (e) {
                console.log('E32A no data')
            }

            try {
                const fetchE21aBack = await btcBusTime(e21aBackBus, dirBack, dirB, e21aBackSeq)
                const allTime = {
                    item1: fetchE21aBack[0] || null,
                    item2: fetchE21aBack[1] || null,
                    item3: fetchE21aBack[2] || null
                }
                setE21aBackTime(allTime);
            } catch (e) {
                console.log('E21A no data')
            }

            try {
                const fetchE21dBack = await btcBusTime(e21dBackBus, dirBack, dirB, e21dBackSeq)
                const allTime = {
                    item1: fetchE21dBack[0] || null,
                    item2: fetchE21dBack[1] || null,
                    item3: fetchE21dBack[2] || null
                }
                setE21dBackTime(allTime);
            } catch (e) {
                console.log('E21D no data')
            }

            try {
                const fetchE11aBack = await btcBusTime(e11aBackBus, dirBack, dirB, e11aBackSeq)
                const allTime = {
                    item1: fetchE11aBack[0] || null,
                    item2: fetchE11aBack[1] || null,
                    item3: fetchE11aBack[2] || null
                }
                setE11aBackTime(allTime);
            } catch (e) {
                console.log('E11A no data')
            }

            try {
                const fetchE11bBack = await btcBusTime(e11bBackBus, dirBack, dirB, e11bBackSeq)
                const allTime = {
                    item1: fetchE11bBack[0] || null,
                    item2: fetchE11bBack[1] || null,
                    item3: fetchE11bBack[2] || null
                }
                setE11bBackTime(allTime);
            } catch (e) {
                console.log('E11B no data')
            }

        }
        fetchData()
        
        const interval = setInterval(() => {
            setTimeout(new Date());
        }, 5000);
        return () => clearInterval(interval);

    }, [timeout])

    const min = '分鐘'
    const noBus = '沒有班次'
    const busStopGo = '裕雅苑出去'
    const busStopBack = '回去裕雅苑'
    return (
        <div className="mainPage">
            <h2>Tung Chung Yu Nga Court<br></br>{busStopGo}</h2>
            {/* 裕雅苑站 */}
            <div className="allBus"> 
                <div>
                    <div className="carName">{e32aBus}</div>
                    { e32aTime !== null ? (
                        <div>
                            {e32aTime.item1 !== null && <div> {e32aTime.item1} {min}</div>}
                            {e32aTime.item2 !== null && <div> {e32aTime.item2} {min}</div>}
                            {e32aTime.item3 !== null && <div> {e32aTime.item3} {min}</div>}
                        </div>
                    ) : (
                        <div>{e32aBus}<br></br>{noBus}</div>
                    )}
                </div>

                <div>
                    <div className="carName">{e36aBus}</div>
                    { e36aTime !== null ? (
                        <div>
                            {e36aTime.item1 !== null && <div> {e36aTime.item1} {min}</div>}
                            {e36aTime.item2 !== null && <div> {e36aTime.item2} {min}</div>}
                            {e36aTime.item3 !== null && <div> {e36aTime.item3} {min}</div>}
                        </div>
                    ) : (
                        <div>{e36aBus}<br></br>{noBus}</div>
                    )}
                </div>

                <div>
                    <div className="carName">{e21aBus}</div>
                    { e21aTime !== null ? (
                        <div>
                            {e21aTime.item1 !== null && <div> {e21aTime.item1} {min}</div>}
                            {e21aTime.item2 !== null && <div> {e21aTime.item2} {min}</div>}
                            {e21aTime.item3 !== null && <div> {e21aTime.item3} {min}</div>}
                        </div>
                    ) : (
                        <div>{e21aBus}<br></br>{noBus}</div>
                    )}
                </div>

                <div>
                    <div className="carName">{e21dBus}</div>
                    { e21dTime !== null ? (
                        <div>
                            {e21dTime.item1 !== null && <div> {e21dTime.item1} {min}</div>}
                            {e21dTime.item2 !== null && <div> {e21dTime.item2} {min}</div>}
                            {e21dTime.item3 !== null && <div> {e21dTime.item3} {min}</div>}
                        </div>
                    ) : (
                        <div>{e21dBus}<br></br>{noBus}</div>
                    )}
                </div>

                <div>
                    <div className="carName">{e11aBus}</div>
                    { e11aTime !== null ? (
                        <div>
                            {e11aTime.item1 !== null && <div> {e11aTime.item1} {min}</div>}
                            {e11aTime.item2 !== null && <div> {e11aTime.item2} {min}</div>}
                            {e11aTime.item3 !== null && <div> {e11aTime.item3} {min}</div>}
                        </div>
                    ) : (
                        <div>{e11aBus}<br></br>{noBus}</div>
                    )}
                </div>

                <div>
                    <div className="carName">{e11bBus}</div>
                    { e11bTime !== null ? (
                        <div>
                            {e11bTime.item1 !== null && <div> {e11bTime.item1} {min}</div>}
                            {e11bTime.item2 !== null && <div> {e11bTime.item2} {min}</div>}
                            {e11bTime.item3 !== null && <div> {e11bTime.item3} {min}</div>}
                        </div>
                    ) : (
                        <div>{e11bBus}<br></br>{noBus}</div>
                    )}
                </div>
            </div>

            <h2>Lantau Link Interchange Station<br></br>{busStopBack}</h2>
            
            {/* 轉車站 */}
            <div className="allBus"> 
                <div>
                    <div className="carName">{e32aBackBus} </div>
                    { e32aBackTime !== null ? (
                        <div>
                            {e32aBackTime.item1 !== null && <div> {e32aBackTime.item1} {min}</div>}
                            {e32aBackTime.item2 !== null && <div> {e32aBackTime.item2} {min}</div>}
                            {e32aBackTime.item3 !== null && <div> {e32aBackTime.item3} {min}</div>}
                        </div>
                    ) : (
                        <div>{e32aBackBus}<br></br>{noBus}</div>
                    )}
                </div>

                <div>
                    <div className="carName">{e21aBackBus} </div>
                    { e21aBackTime !== null ? (
                        <div>
                            {e21aBackTime.item1 !== null && <div> {e21aBackTime.item1} {min}</div>}
                            {e21aBackTime.item2 !== null && <div> {e21aBackTime.item2} {min}</div>}
                            {e21aBackTime.item3 !== null && <div> {e21aBackTime.item3} {min}</div>}
                        </div>
                    ) : (
                        <div>{e21aBackBus}<br></br>{noBus}</div>
                    )}
                </div>

                <div>
                    <div className="carName">{e21dBackBus} </div>
                    { e21dBackTime !== null ? (
                        <div>
                            {e21dBackTime.item1 !== null && <div> {e21dBackTime.item1} {min}</div>}
                            {e21dBackTime.item2 !== null && <div> {e21dBackTime.item2} {min}</div>}
                            {e21dBackTime.item3 !== null && <div> {e21dBackTime.item3} {min}</div>}
                        </div>
                    ) : (
                        <div>{e21dBackBus}<br></br>{noBus}</div>
                    )}
                </div>

                <div>
                    <div className="carName">{e11aBackBus}</div>
                    { e11aBackTime !== null ? (
                        <div>
                            {e11aBackTime.item1 !== null && <div> {e11aBackTime.item1} {min}</div>}
                            {e11aBackTime.item2 !== null && <div> {e11aBackTime.item2} {min}</div>}
                            {e11aBackTime.item3 !== null && <div> {e11aBackTime.item3} {min}</div>}
                        </div>
                    ) : (
                        <div>{e11aBackBus}<br></br>{noBus}</div>
                    )}
                </div>

                <div>
                    <div className="carName">{e11bBackBus}</div>
                    { e11bBackTime !== null ? (
                        <div>
                            {e11bBackTime.item1 !== null && <div> {e11bBackTime.item1} {min}</div>}
                            {e11bBackTime.item2 !== null && <div> {e11bBackTime.item2} {min}</div>}
                            {e11bBackTime.item3 !== null && <div> {e11bBackTime.item3} {min}</div>}
                        </div>
                    ) : (
                        <div>{e11bBackBus}<br></br>{noBus}</div>
                    )}
                </div>
            </div>
        </div>
    );
}


import { AllBusForYuNga } from './AllBusForYuNga';
import './App.css';
import { MainPage } from './MainPage';
import { Nav } from './Nav';
import { STtoTC } from './ST-TC';
import { TCtoST } from './TC-ST';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'


function App() {
  return (
    <Router>
      <div className="App">
      <Nav />
        <Routes>
          <Route path='/' Component={MainPage}/>
          <Route path='/TCtoST' Component={TCtoST}/>
          <Route path='/STtoTC' Component={STtoTC}/>
          <Route path='/AllBusForYuNga' Component={AllBusForYuNga}/>
        </Routes>
      </div>
    </Router>


  );
}

export default App;
